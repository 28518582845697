import { createSlice } from "@reduxjs/toolkit";

const initialCultivationsState = {
  listLoading: false,
  actionsLoading: false,
  entities: null,
  lastError: null
};

export const callTypes = {
  list: "list",
  action: "action"
};

export const cultivationsSlice = createSlice({
  name: "cultivations",
  initialState: initialCultivationsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    cultivationsFetched: (state, action) => {
      const entities = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
    },
    cultivationCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { cultivation } = action.payload;
      state.entities.push(cultivation);
      state.cultivationCreated = true;
    },
    cultivationCreatedReset: (state, action) => {
      state.cultivationCreated = false;
    },
    cultivationUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { cultivation } = action.payload;
      state.entities.push(cultivation);
      state.cultivationEdited = true;
    },
    cultivationUpdatedReset: (state, action) => {
      state.cultivationEdited = false;
    },
    cultivationDeleted: (state, action) => {
      const id = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.cultivationDeleted = true;
      state.entities = state.entities.filter(el => el.id !== id);
    },
    cultivationDeletedReset: (state, action) => {
      state.cultivationDeleted = false;
    },
    cultivationFetched: (state, action) => {
      state.actionsLoading = false;
      state.cultivation = action.payload.cultivation;
      state.error = null;
    },
    cultivationDiseasesUpdated: (state, action) => {
      const id = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.cultivationDiseasesUpdated = true;
    },
    cultivationDiseasesUpdatedReset: (state, action) => {
      state.cultivationDiseasesUpdated = false;
    },
    cultivationInsectsUpdated: (state, action) => {
      const id = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.cultivationInsectsUpdated = true;
    },
    cultivationInsectsUpdatedReset: (state, action) => {
      state.cultivationInsectsUpdated = false;
    },
    cultivationDiseaseResultsUpdated: (state, action) => {
      state.actionsLoading = false;
      state.cultivation = action.payload.cultivation;
      state.cultivationDiseaseResultsUpdated = true;
      state.error = null;
    },
    cultivationDiseaseResultsUpdatedReset: (state, action) => {
      state.cultivationDiseaseResultsUpdated = false;
    },
    cultivationDiseaseDatesUpdated: (state, action) => {
      state.actionsLoading = false;
      state.cultivation = action.payload.cultivation;
      state.cultivationDiseaseDatesUpdated = true;
      state.error = null;
    },
    cultivationDiseaseDatesUpdatedReset: (state, action) => {
      state.cultivationDiseaseDatesUpdated = false;
    },
    stationCultivationsFetched: (state, action) => {
      state.actionsLoading = false;
      state.stationCultivations = action.payload.stationCultivations;
      state.error = null;
    },
  }
});
