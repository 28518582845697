import { createSlice } from "@reduxjs/toolkit";

const initialStationsState = {
  listLoading: false,
  actionsLoading: false,
  entities: null,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const stationsSlice = createSlice({
  name: "stations",
  initialState: initialStationsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    stationsFetched: (state, action) => {
      const entities = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
    },
    stationFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.stationForEdit = action.payload.stationForEdit;
    },
    stationUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.stationUpdated = true;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.station.id) {
          return action.payload.station;
        }
        return entity;
      });
    },
    stationUpdatedReset: (state, action) => {
      state.stationUpdated = false;
    },
    stationForecastFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;

      const { stationForecast } = action.payload;
      state.stationForecast = stationForecast;
      console.log(stationForecast);
    }
  }
});
