import { MsalAuthProvider, LoginType } from 'react-aad-msal';

// It should be in configuration
var host = window.location.host;
var clientId = host == "admin.farmsmart.pl"
  ? "77afa53d-a142-4897-9f9d-faadccfa7969"
  : "8c26658c-2fa0-4cc7-929a-69cdc1636269"

// Msal Configurations
const config = {
  auth: {
    authority: 'https://login.microsoftonline.com/39c69447-f09a-418a-853a-024ebbbe9cb0',
    clientId: clientId,
    redirectUri: window.location.origin
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  }
};
 
// // Authentication Parameters
// const authenticationParameters = {
//   scopes: [
//     '<property (i.e. user.read)>',
//     'https://<your-tenant-name>.onmicrosoft.com/<your-application-name>/<scope (i.e. demo.read)>'
//   ]
// }
 
// Options
const options = {
  loginType: LoginType.Redirect
}
 
export const authProvider = new MsalAuthProvider(config, null, options)