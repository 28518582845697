import { createSlice } from "@reduxjs/toolkit";

const initialDiseasesState = {
  listLoading: false,
  actionsLoading: false,
  entities: null,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const diseasesSlice = createSlice({
  name: "diseases",
  initialState: initialDiseasesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    diseasesFetched: (state, action) => {
      const entities = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.disease = {};
    },
    diseaseFetched: (state, action) => {
      const disease = action.payload;
      state.listLoading = false;
      state.error = null;
      state.disease = disease;
    },
    diseaseDataResultsFetched: (state, action) => {
      const results = action.payload;
      state.listLoading = false;
      state.error = null;
      state.results = results;
    },
    diseaseResultsUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { disease } = action.payload;
      state.disease = disease;
      state.diseaseResultsEdited = true;
    },
    diseaseResultsUpdatedReset: (state, action) => {
      state.diseaseResultsEdited = false;
    },
  }
});
