import { createSlice } from "@reduxjs/toolkit";
import { DateTime } from 'luxon';

const initialDataState = {
  listLoading: false,
  actionsLoading: false,
  diseaseData: null,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const dataSlice = createSlice({
  name: "data",
  initialState: initialDataState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    diseaseDataFetched: (state, action) => {
      const diseaseData = action.payload;
      state.listLoading = false;
      state.error = null;
      state.diseaseData = diseaseData;
    },
    sensorDataFetched: (state, action) => {
      const sensorData = action.payload;
      state.sensorData = sensorData;
      state.listLoading = false;
      state.error = null;
    },
    lastSensorInfoFetched: (state, action) => {
      const lastSensorInfo = action.payload;
      state.lastSensorInfo = lastSensorInfo;
      state.listLoading = false;
      state.error = null;
    },
  }
});
