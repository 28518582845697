import { createSlice } from "@reduxjs/toolkit";

const initialInsectModelState = {
  listLoading: false,
  actionsLoading: false,
  entities: null,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const insectModelsSlice = createSlice({
  name: "insectModels",
  initialState: initialInsectModelState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    insectModelsFetched: (state, action) => {
      const entities = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
    },
    insectModelFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.modelForEdit = action.payload.modelForEdit;
    },
    insectModelCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.insectModel);
      state.modelCreated = true;
    },
    insectModelCreatedReset: (state, action) => {
      state.modelCreated = false;
    },
    insectModelDeleted: (state, action) => {
      const id = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== id);
    },
    insectModelUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.map(entity => {
      //   if (entity.id === action.payload.insectModel.id) {
      //     return action.payload.insectModel;
      //   }
      //   return entity;
      // });
      state.modelUpdated = true;
    },
    insectModelUpdatedReset: (state, action) => {
      state.modelUpdated = false;
    },
  }
});
