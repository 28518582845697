import { createSlice } from "@reduxjs/toolkit";

const initialInsectOccurrencesState = {
  listLoading: false,
  actionsLoading: false,
  entities: null,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const insectOccurrencesSlice = createSlice({
  name: "insectOccurrences",
  initialState: initialInsectOccurrencesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // findInsectOccurrences
    insectOccurrencesFetched: (state, action) => {
      const entities = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
    },
    insectOccurrenceFetched: (state, action) => {
      state.actionLoading = false;
      state.insectOccurrenceToEdit = action.payload.insectOccurrenceToEdit;
      state.error = null;
    },
    // createInsectOccurrence
    insectOccurrenceCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.occurrenceCreated = true;
      state.entities.push(action.payload.insectOccurrence);
    },
    insectOccurrenceCreatedReset: (state, action) => {
      state.occurrenceCreated = false;
    },
    insectOccurrenceDeleted: (state, action) => {
      const id = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.insectOccurrenceDeleted = true;
      state.entities = state.entities.filter(el => el.id !== id);
    },
    insectOccurrenceDeletedReset: (state, action) => {
      state.insectOccurrenceDeleted = false;
    },
    insectOccurrenceUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.occurrenceUpdated = true;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.insectOccurrence.id) {
          return action.payload.insectOccurrence;
        }
        return entity;
      });
    },
    insectOccurrenceUpdatedReset: (state, action) => {
      state.occurrenceUpdated = false;
    },
    setTrapFilter: (state, action) => {
      const { selectedTrap } = action.payload;
      state.trapFilter = selectedTrap;
    },
    setInsectFilter: (state, action) => {
      const { selectedInsect } = action.payload;
      state.insectFilter = selectedInsect;
    }
  }
});
