import { createSlice } from "@reduxjs/toolkit";

const initialSensorGroupsState = {
  listLoading: false,
  actionsLoading: false,
  entities: null,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const sensorGroupsSlice = createSlice({
  name: "sensorGroups",
  initialState: initialSensorGroupsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    sensorGroupsFetched: (state, action) => {
      const entities = action.payload;
      state.entities = entities;
      state.listLoading = false;
      state.error = null;
    },
    sensorGroupFetched: (state, action) => {
      const entity = action.payload;
      state.entity = entity;
      state.listLoading = false;
      state.error = null;
    },
    sensorGroupUpdated: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.sensorGroupUpdated = true;
    },
    resetSensorGroup: (state, action) => {
      state.entity = null;
    },
    sensorGroupUpdatedReset: (state, action) => {
      state.sensorGroupUpdated = false;
    }
  }
});
