import { createSlice } from "@reduxjs/toolkit";

const initialTrapsState = {
  listLoading: false,
  actionsLoading: false,
  entities: null,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const trapsSlice = createSlice({
  name: "traps",
  initialState: initialTrapsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // findTraps
    trapsFetched: (state, action) => {
      const entities = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
    },
    trapsInsectsFetched: (state, action) => {
      const insects = action.payload;
      state.listLoading = false;
      state.error = null;
      state.trapsInsects = insects;
    },
    trapInsectsFetched: (state, action) => {
      const insects = action.payload;
      state.listLoading = false;
      state.error = null;
      state.trapInsects = insects;
    },
    trapInsectsUpdated: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.trapInsectsSaved = true;
    },
    trapInsectsUpdatedReset: (state, action) => {
      state.trapInsectsSaved = false;
    }
  }
});
