import { createSlice } from "@reduxjs/toolkit";

const initialAlertsState = {
  listLoading: false,
  actionsLoading: false,
  entities: null,
  lastError: null,
  stationEntities: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const alertsSlice = createSlice({
  name: "alerts",
  initialState: initialAlertsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    alertsFetched: (state, action) => {
      const entities = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
    },
    stationAlertsFetched: (state, action) => {
      const entities = action.payload;
      state.listLoading = false;
      state.error = null;
      state.stationEntities = entities;
    }
  }
});
