import { createSlice } from "@reduxjs/toolkit";

const initialPricingState = {
  prices: {},
  saved: false
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const pricingSlice = createSlice({
  name: "pricing",
  initialState: initialPricingState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    pricesFetched: (state, action) => {
      const prices = action.payload;
      state.prices = prices;
      state.listLoading = false;
      state.error = null;
    },
    pricesSaved: (state, action) => {
      const res = action.payload;
      state.listLoading = false;
      state.saved = true;
      state.error = null;
    },
    pricesSavedReset: (state, action) => {
      state.saved = false;
    }
  }
});
