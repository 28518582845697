import { createSlice } from "@reduxjs/toolkit";

const initialStationsTrapsConnectionsState = {
  listLoading: false,
  actionsLoading: false,
  entities: null,
  lastError: null
};

export const callTypes = {
  list: "list",
  action: "action"
};

export const stationsTrapsConnectionsSlice = createSlice({
  name: "stationsTrapsConnections",
  initialState: initialStationsTrapsConnectionsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    stationsTrapsConnectionsFetched: (state, action) => {
      const entities = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
    },
    stationTrapConnectionFetched: (state, action) => {
      const stationTrapConnection = action.payload;
      state.listLoading = false;
      state.error = null;
      state.stationTrapConnection = stationTrapConnection;
    },
    stationTrapConnectionEdited: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.stationTrapConnectionEdited = true;
    },
    stationTrapConnectionEditedReset: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.stationTrapConnectionEdited = false;
    },
    stationTrapConnectionCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      let entities = state.entities;
      state.entities =  [action.payload.stationTrapConnection, ...entities];
      state.stationTrapConnectionCreated = true;
    },
    stationTrapConnectionCreatedReset: (state, action) => {
      state.stationTrapConnectionCreated = false;
    },
    stationTrapConnectionUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.stationTrapConnectionUpdated = true;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.stationTrapConnection.id) {
          return action.payload.stationTrapConnection;
        }
        return entity;
      });
    },
    stationTrapConnectionUpdatedReset: (state, action) => {
      state.stationTrapConnectionUpdated = false;
    },
    stationTrapConnectionDeleted: (state, action) => {
      const id = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.stationTrapConnectionDeleted = true;
      state.entities = state.entities.filter(el => el.id !== id);
    },
    stationUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.stationUpdated = true;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.station.id) {
          return action.payload.station;
        }
        return entity;
      });
    },
    stationTrapConnectionDeletedReset: (state, action) => {
      state.stationTrapConnectionDeleted = false;
    }
  }
});
