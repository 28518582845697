import { createSlice } from "@reduxjs/toolkit";

const initialOrdersState = {
  orders: [],
  order: {},
  saved: false
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const ordersSlice = createSlice({
  name: "orders",
  initialState: initialOrdersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    ordersFetched: (state, action) => {
      const orders = action.payload;
      state.orders = orders;
      state.listLoading = false;
      state.error = null;
    },
    orderFetched: (state, action) => {
      const order = action.payload;
      state.order = order;
      state.listLoading = false;
      state.error = null;
    },
    orderSaved: (state, action) => {
      const order = action.payload;
      state.listLoading = false;
      state.saved = true;
      state.error = null;
    },
    orderSavedReset: (state, action) => {
      state.saved = false;
    }
  }
});
