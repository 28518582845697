import { combineReducers } from "redux";
import * as auth from "../app/modules/Auth/_redux/authRedux";
import { trapsSlice } from "../app/modules/Data/traps/trapsSlice";
import { insectsSlice } from "../app/modules/Data/insects/insectsSlice";
import { insectModelsSlice } from "../app/modules/Data/insectModels/insectModelsSlice";
import { insectOccurrencesSlice } from "../app/modules/Data/insectOccurrences/insectOccurrencesSlice";
import { stationsTrapsConnectionsSlice } from "../app/modules/Data/stationsTrapsConnections/stationsTrapsConnectionsSlice";
import { stationsSlice } from "../app/modules/Data/stations/stationsSlice";
import { customersSlice } from "../app/modules/ECommerce/_redux/customers/customersSlice";
import { productsSlice } from "../app/modules/ECommerce/_redux/products/productsSlice";
import { remarksSlice } from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
import { specificationsSlice } from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";
import { sensorGroupsSlice } from "../app/modules/Data/sensorGroups/sensorGroupsSlice";
import { alertsSlice } from "../app/modules/Data/alerts/alertsSlice";
import { cultivationsSlice } from "../app/modules/Data/cultivations/cultivationsSlice";
import { diseasesSlice } from "../app/modules/Data/diseaseModels/diseasesSlice";
import { dataSlice } from "../app/modules/Data/data/dataSlice";
import { usersSlice } from "../app/modules/Data/users/usersSlice";
import { smsSlice } from "../app/modules/Data/sms/smsSlice";
import { pricingSlice } from "../app/modules/Data/pricing/pricingSlice";
import { ordersSlice } from "../app/modules/Data/orders/ordersSlice";
import { codesSlice } from "../app/modules/Data/discountCodes/codesSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  traps: trapsSlice.reducer,
  insects: insectsSlice.reducer,
  insectModels: insectModelsSlice.reducer,
  insectOccurrences: insectOccurrencesSlice.reducer,
  stationsTrapsConnections: stationsTrapsConnectionsSlice.reducer,
  stations: stationsSlice.reducer,
  customers: customersSlice.reducer,
  products: productsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
  sensorGroups: sensorGroupsSlice.reducer,
  alerts: alertsSlice.reducer,
  cultivations: cultivationsSlice.reducer,
  diseases: diseasesSlice.reducer,
  data: dataSlice.reducer,
  users: usersSlice.reducer,
  sms: smsSlice.reducer,
  pricing: pricingSlice.reducer,
  orders: ordersSlice.reducer,
  codes: codesSlice.reducer
});