import { createSlice } from "@reduxjs/toolkit";

const initialUsersState = {
  listLoading: false,
  actionsLoading: false,
  entities: null,
  lastError: null,
  subscriptionUpdated: false,
  subscriptionDeleted: false
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const usersSlice = createSlice({
  name: "users",
  initialState: initialUsersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    usersFetched: (state, action) => {
      const entities = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
    },
    activeUsersFetched: (state, action) => {
      const entities = action.payload;
      state.listLoading = false;
      state.error = null;
      state.activeUsers = entities;
    },
    userFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.userForEdit = action.payload.userForEdit;
    },
    userUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.userUpdated = true;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.user.id) {
          return action.payload.user;
        }
        return entity;
      });
    },
    userUpdatedReset: (state, action) => {
      state.userUpdated = false;
    },
    userCreated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.userCreated = true;
      state.entities.push(action.payload.user);
    },
    userCreatedReset: (state, action) => {
      state.userCreated = false;
    },
    userSubscriptionsFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.userSubscriptions = action.payload.userSubscriptions;
    },
    subscriptionsFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.subscriptions = action.payload.subscriptions;
    },
    subscriptionFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.subscription = action.payload.subscription;
    },
    subscriptionReset: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.subscription = null;
    },
    subscriptionUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.subscriptionUpdated = true;
      state.userSubscription = action.payload.subscription;
    },
    subscriptionUpdatedReset: (state, action) => {
      state.subscriptionUpdated = false;
    },
    subscriptionCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.userSubscriptions.push(action.payload.subscription);
      state.subscriptionCreated = true;
    },
    subscriptionCreatedReset: (state, action) => {
      state.subscriptionCreated = false;
    },
    subscriptionDeleted: (state, action) => {
      const id = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.subscriptionDeleted = true;
      state.userSubscriptions = state.userSubscriptions.filter(el => el.id !== id);
    },
    subscriptionDeletedReset: (state, action) => {
      state.subscriptionDeleted = false;
    },
    stationCultivationUsersFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.stationCultivationUsers = action.payload.stationCultivationUsers;
    },
    userDeleted: (state, action) => {
      const id = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.userDeleted = true;
      state.entities = state.entities.filter(el => el.id !== id);
    },
    userDeletedReset: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.userDeleted = false;
    }
  }
});
