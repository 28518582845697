import { createSlice } from "@reduxjs/toolkit";

const initialInsectsState = {
  listLoading: false,
  actionsLoading: false,
  entities: null,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const insectsSlice = createSlice({
  name: "insect",
  initialState: initialInsectsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    insectsFetched: (state, action) => {
      const entities = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
    },
    insectDeleted: (state, action) => {
      const id = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.insectDeleted = true;
      state.entities = state.entities.filter(el => el.id !== id);
    },
    insectDeletedReset: (state, action) => {
      state.insectDeleted = false;
    },
    insectCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.insect);
      state.insectCreated = true;
    },
    insectCreatedReset: (state, action) => {
      state.insectCreated = false;
    },
    insectFetched: (state, action) => {
      state.actionsLoading = false;
      state.insectForEdit = action.payload.insectForEdit;
      state.error = null;
    },
    insectUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.insectUpdated = true;
      state.insectForEdit = null;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.insect.id) {
          return action.payload.insect;
        }
        return entity;
      });
    },
    insectUpdatedReset: (state, action) => {
      state.insectUpdated = false;
    },
  }
});
