import { createSlice } from "@reduxjs/toolkit";

const initialCodesState = {
  listLoading: false,
  actionsLoading: false,
  entities: null,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const codesSlice = createSlice({
  name: "codes",
  initialState: initialCodesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    codesFetched: (state, action) => {
      const entities = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
    },
    codeDeleted: (state, action) => {
      const id = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.codeDeleted = true;
      state.entities = state.entities.filter(el => el.id !== id);
    },
    codeDeletedReset: (state, action) => {
      state.codeDeleted = false;
    },
    codesCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities = [...state.entities, ...action.payload.codes];
      state.codesCreated = true;
    },
    codesCreatedReset: (state, action) => {
      state.codesCreated = false;
    }
  }
});
