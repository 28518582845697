export default function setupAxios(axios, store) {

  var host = window.location.host;
  axios.defaults.baseURL = getBaseURL(host) + 'api/admin';

  axios.interceptors.request.use(
    config => {
      const {
        auth: { jwtIdToken }
      } = store.getState();

      if (jwtIdToken) {
        config.headers.Authorization = `Bearer ${jwtIdToken}`;
      }

      return config;
    },
    err => Promise.reject(err)
  );

  axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if (401 === error.response.status) {
      window.location.reload();
    } else {
      return Promise.reject(error);
    }
  });

  function getBaseURL(host) {
    switch (host) {
      case 'localhost:3000': return 'http://localhost:3001/';
      case 'localhost:3006': return 'http://localhost:3001/';
      case 'dev.farmsmart.pl': return 'https://api.dev.admin.farmsmart.pl/';
      case 'farmsmart.pl': return 'https://api.admin.farmsmart.pl/';
      case 'fsdevusersa.z6.web.core.windows.net': return 'https://api.dev.admin.farmsmart.pl/';
      case 'fsprodusersa.z6.web.core.windows.net': return 'https://api.admin.farmsmart.pl/';
      case 'dev.admin.farmsmart.pl': return 'https://api.dev.admin.farmsmart.pl/';
      case 'admin.farmsmart.pl': return 'https://api.admin.farmsmart.pl/';
      default: throw Error('Invalid host URL');
    }
  }
}
